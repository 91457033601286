exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ar-js": () => import("./../../../src/pages/about.ar.js" /* webpackChunkName: "component---src-pages-about-ar-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-contact-ar-js": () => import("./../../../src/pages/contact.ar.js" /* webpackChunkName: "component---src-pages-contact-ar-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-event-attendance-ar-js": () => import("./../../../src/pages/event-attendance.ar.js" /* webpackChunkName: "component---src-pages-event-attendance-ar-js" */),
  "component---src-pages-follow-us-ar-js": () => import("./../../../src/pages/follow-us.ar.js" /* webpackChunkName: "component---src-pages-follow-us-ar-js" */),
  "component---src-pages-home-old-ar-js": () => import("./../../../src/pages/home-old.ar.js" /* webpackChunkName: "component---src-pages-home-old-ar-js" */),
  "component---src-pages-home-old-en-js": () => import("./../../../src/pages/home-old.en.js" /* webpackChunkName: "component---src-pages-home-old-en-js" */),
  "component---src-pages-index-ar-js": () => import("./../../../src/pages/index.ar.js" /* webpackChunkName: "component---src-pages-index-ar-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-project-ar-js": () => import("./../../../src/pages/project.ar.js" /* webpackChunkName: "component---src-pages-project-ar-js" */),
  "component---src-pages-project-en-js": () => import("./../../../src/pages/project.en.js" /* webpackChunkName: "component---src-pages-project-en-js" */),
  "component---src-templates-project-template-project-template-js": () => import("./../../../src/templates/project-template/project-template.js" /* webpackChunkName: "component---src-templates-project-template-project-template-js" */)
}

